<template>
  <div>
    <div class="container">
      <div class="wrapper">
        <!-- <Header title="輸入授權序號" backto="/user" /> -->
        <div class="head mb-5">
          <div class="justify-content-center row">
            <div class="col-lg-7">
              <img src="@/assets/registor_header_3.png" />
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-lg-7">
            <h6>授權序號</h6>
            <b-form-input
              id="code"
              type="text"
              v-model="$v.code.$model"
              :state="validateState('code')"
            />
            <b-form-invalid-feedback id="code">必填</b-form-invalid-feedback>
            <div class="mt-3">
              <a href="#" @click="$router.push('/payment/order')">沒有序號？使用信用卡開通！</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="d-flex">
        <div class="w-100" @click="$router.replace('/payment/order')">
          <Button name="使用信用卡開通"></Button>
        </div>
        <div class="w-100" @click="onSubmit">
          <Button class="highlight" name="序號開通"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { parseISO, format } from 'date-fns';
import { plan } from '@/mixins';
import Button from '@/elements/Button.vue';

export default {
  mixins: [plan, validationMixin],
  computed: {
    ...mapState({
      records: (state) => state.records.records,
    }),
  },
  data() {
    return {
      code: '',
      blocker: false,
    };
  },
  components: {
    Button,
  },
  validations: {
    code: { required },
  },
  methods: {
    parseISO,
    format,
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    onSubmit(e) {
      e.preventDefault();
      if (this.blocker) {
        return;
      }
      this.blocker = true;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.addPlan({
        licenseKey: this.code,
        onSuccess: () => {
          this.$router.replace('/');
        },
        onFailed: () => {
          alert('此序號無法使用');
          this.blocker = false;
        },
      });
    },
  },
};
</script>

<style>
.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.blocker {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 999;
  background-color: black;
  opacity: 0.6;
}
</style>
